import React from 'react'
import axios from 'axios'
import { Do } from 'fp-ts-contrib/lib/Do'
import { useSpring, animated } from 'react-spring'
import * as O from 'fp-ts/lib/Option'
import * as E from 'fp-ts/lib/Either'
import * as A from 'fp-ts/lib/Array'
import { pipe } from 'fp-ts/lib/pipeable'

import Layout from '../components/layout'
import { Link } from 'gatsby'
import { newIO } from '../util/io'
import { nonEmpty, isValidEmail } from '../util/validation'

type ContactForm = {
  nameOpt: O.Option<string>
  emailOpt: O.Option<string>
  messageOpt: O.Option<string>
}

type ValidatedContactForm = {
  name: string
  email: string
  message: string
}

export const ContactFitKidz4Fun = () => {
  const springOpacity = useSpring({
    from: { opacity: 0, transform: 'translateX(90px)' },
    to: { opacity: 1, transform: 'translateX(0)' },
  })

  const [nameOpt, setNameOpt] = React.useState<O.Option<string>>(O.none)
  const [emailOpt, setEmailOpt] = React.useState<O.Option<string>>(O.none)
  const [phoneOpt, setPhoneOpt] = React.useState<O.Option<string>>(O.none)
  const [messageOpt, setMessageOpt] = React.useState<O.Option<string>>(O.none)
  const [validationErrors, setValidationErrors] = React.useState<
    O.Option<Array<string>>
  >(O.none)
  const [displayThankYouMessage, setDisplayThankYouMessage] = React.useState(
    false
  )

  const springThankYouMessage = useSpring({
    from: { opacity: 0, transform: 'translateX(90px)' },
    to: { opacity: displayThankYouMessage ? 1 : 0, transform: 'translateX(0)' },
  })
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const validateContactForm = (contactForm: ContactForm) =>
    Do(E.getValidation(A.getMonoid<string>()))
      .sequenceS({
        nameIsNotEmpty: nonEmpty('Name cannot be empty', contactForm.nameOpt),
        emailIsValid: isValidEmail(
          'Valid email required',
          contactForm.emailOpt
        ),
        messageIsNotEmpty: nonEmpty(
          'Message cannot be empty',
          contactForm.messageOpt
        ),
      })
      .return(({ nameIsNotEmpty, emailIsValid, messageIsNotEmpty }) => ({
        name: nameIsNotEmpty,
        email: emailIsValid,
        message: messageIsNotEmpty,
      }))

  const handleSubmission = () =>
    pipe(
      validateContactForm({
        nameOpt,
        emailOpt,
        messageOpt,
      }),
      E.fold(
        errors => newIO(() => setValidationErrors(O.some(errors))),
        validForm =>
          newIO(() => {
            const payload = {
              ...validForm,
              phone: O.toUndefined(phoneOpt),
            }
            setValidationErrors(O.none)
            setIsSubmitting(true)

            axios
              .post('/api/send-contact-message-to-fk4f', payload)
              .then(_ => {
                setIsSubmitting(false)
                setDisplayThankYouMessage(true)
              })
              .catch(() =>
                setValidationErrors(
                  O.some([
                    'Unable to send email at this time. Please try reaching us at (407) 777-4903',
                  ])
                )
              )
          })
      )
    )()

  return (
    <Layout seoTitle="Contact Fit Kidz 4 Fun">
      <animated.div style={springOpacity} className="relative bg-gray-50">
        <div className="absolute inset-0">
          <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-100"></div>
        </div>
        <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
          <div className="bg-gray-100 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
            <div className="max-w-lg mx-auto">
              <h2 className="text-2xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-3xl sm:leading-9">
                Get in touch with us
              </h2>
              <p className="mt-3 text-lg leading-6 text-gray-500">
                If you have any questions or concerns, we&apos;d love to hear
                from you. At Fit Kidz 4 Fun, your satisfaction and peace of mind
                is our top priority.
              </p>
              <dl className="mt-8 text-base leading-6 text-gray-500">
                <div>
                  <dt className="sr-only">Postal address</dt>
                  <dd>
                    <p>6897 W Colonial Dr</p>
                    <p>Orlando, FL 32818</p>
                  </dd>
                </div>
                <div className="mt-3">
                  <dt className="sr-only">Email</dt>
                  <dd className="flex">
                    <svg
                      className="flex-shrink-0 h-6 w-6 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                      />
                    </svg>
                    <span className="ml-3">FitKidz@cortizfitness.com</span>
                  </dd>
                </div>
              </dl>
              {/* <p className="mt-6 text-base leading-6 text-gray-500">
                Interested in joining the team?
              </p>
              <Link to="#" className="text-sm text-gray-400 underline">
                View current job openings
              </Link> */}
            </div>
          </div>
          <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
            <div className="max-w-lg mx-auto lg:max-w-none">
              <div className="grid grid-cols-1 row-gap-6">
                <div>
                  <label htmlFor="full_name" className="sr-only">
                    Full name
                  </label>
                  <div className="relative rounded-md shadow-md">
                    <input
                      id="full_name"
                      onChange={e => {
                        pipe(
                          O.fromNullable(e.target.value),
                          O.fold(
                            () => newIO(() => setNameOpt(O.none)),
                            n =>
                              newIO(() => {
                                n === ''
                                  ? setNameOpt(O.none)
                                  : setNameOpt(O.some(n))
                              })
                          )
                        )()
                      }}
                      className="form-input block w-full py-3 px-4 placeholder-gray-500"
                      placeholder="Full name"
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="email" className="sr-only">
                    Email
                  </label>
                  <div className="relative rounded-md shadow-md">
                    <input
                      id="email"
                      onChange={e => {
                        pipe(
                          O.fromNullable(e.target.value),
                          O.fold(
                            () => newIO(() => setEmailOpt(O.none)),
                            email_ =>
                              newIO(() => {
                                email_ === ''
                                  ? setEmailOpt(O.none)
                                  : setEmailOpt(O.some(email_))
                              })
                          )
                        )()
                      }}
                      type="email"
                      className="form-input block w-full py-3 px-4 placeholder-gray-500"
                      placeholder="Email"
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="phone" className="sr-only">
                    Phone
                  </label>
                  <div className="relative rounded-md shadow-md">
                    <input
                      id="phone"
                      onChange={e => {
                        pipe(
                          O.fromNullable(e.target.value),
                          O.fold(
                            () => newIO(() => setPhoneOpt(O.none)),
                            p =>
                              newIO(() => {
                                p === ''
                                  ? setPhoneOpt(O.none)
                                  : setPhoneOpt(O.some(p))
                              })
                          )
                        )()
                      }}
                      className="form-input block w-full py-3 px-4 placeholder-gray-500"
                      placeholder="Phone"
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="message" className="sr-only">
                    Message
                  </label>
                  <div className="relative rounded-md shadow-sm">
                    <textarea
                      id="message"
                      onChange={e => {
                        pipe(
                          O.fromNullable(e.target.value),
                          O.fold(
                            () => newIO(() => setMessageOpt(O.none)),
                            m =>
                              newIO(() => {
                                m === ''
                                  ? setMessageOpt(O.none)
                                  : setMessageOpt(O.some(m))
                              })
                          )
                        )()
                      }}
                      rows={4}
                      className="form-input block w-full py-3 px-4 placeholder-gray-500 transition ease-in-out duration-150"
                      placeholder="Message"
                    ></textarea>
                  </div>
                  <div className="mt-4 flex justify-between items-center">
                    <span className="inline-flex rounded-md shadow-sm">
                      <button
                        type="button"
                        disabled={displayThankYouMessage || isSubmitting}
                        onClick={() => handleSubmission()}
                        className={`inline-flex justify-center py-3 px-6 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-accent-400 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 ${
                          displayThankYouMessage || isSubmitting
                            ? 'cursor-not-allowed'
                            : 'hover:bg-accent-500'
                        }`}
                      >
                        {isSubmitting ? (
                          <span>
                            <svg
                              className="h-6 w-11"
                              viewBox="0 0 120 30"
                              fill="#fff"
                            >
                              <circle cx="15" cy="15" r="15">
                                <animate
                                  attributeName="r"
                                  from="15"
                                  to="15"
                                  begin="0s"
                                  dur="0.8s"
                                  values="15;9;15"
                                  calcMode="linear"
                                  repeatCount="indefinite"
                                />
                                <animate
                                  attributeName="fillOpacity"
                                  from="1"
                                  to="1"
                                  begin="0s"
                                  dur="0.8s"
                                  values="1;.5;1"
                                  calcMode="linear"
                                  repeatCount="indefinite"
                                />
                              </circle>
                              <circle cx="60" cy="15" r="9" fillOpacity="0.3">
                                <animate
                                  attributeName="r"
                                  from="9"
                                  to="9"
                                  begin="0s"
                                  dur="0.8s"
                                  values="9;15;9"
                                  calcMode="linear"
                                  repeatCount="indefinite"
                                />
                                <animate
                                  attributeName="fillOpacity"
                                  from="0.5"
                                  to="0.5"
                                  begin="0s"
                                  dur="0.8s"
                                  values=".5;1;.5"
                                  calcMode="linear"
                                  repeatCount="indefinite"
                                />
                              </circle>
                              <circle cx="105" cy="15" r="15">
                                <animate
                                  attributeName="r"
                                  from="15"
                                  to="15"
                                  begin="0s"
                                  dur="0.8s"
                                  values="15;9;15"
                                  calcMode="linear"
                                  repeatCount="indefinite"
                                />
                                <animate
                                  attributeName="fillOpacity"
                                  from="1"
                                  to="1"
                                  begin="0s"
                                  dur="0.8s"
                                  values="1;.5;1"
                                  calcMode="linear"
                                  repeatCount="indefinite"
                                />
                              </circle>
                            </svg>
                          </span>
                        ) : (
                          <span>Submit</span>
                        )}
                      </button>
                    </span>
                    <animated.span
                      style={springThankYouMessage}
                      className="ml-2 text-cool-gray-400 text-right"
                    >
                      Thanks for reaching out, we&apos;ll be in touch shortly
                    </animated.span>
                  </div>
                  {pipe(
                    validationErrors,
                    O.fold(
                      () => null,
                      errors => (
                        <div className="mt-4">
                          {errors.map((error, idx) => (
                            <div
                              key={error + idx}
                              className={`flex text-cool-gray-400 ${
                                idx !== 0 ? 'mt-2' : ''
                              }`}
                            >
                              <svg
                                className="h-6 w-6"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                              >
                                <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 16.094l-4.157-4.104 4.1-4.141-1.849-1.849-4.105 4.159-4.156-4.102-1.833 1.834 4.161 4.12-4.104 4.157 1.834 1.832 4.118-4.159 4.143 4.102 1.848-1.849z" />
                              </svg>
                              <span className="ml-1 text-md leading-6 text-cool-gray-400">
                                {error}
                              </span>
                            </div>
                          ))}
                        </div>
                      )
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </animated.div>
    </Layout>
  )
}

export default ContactFitKidz4Fun
